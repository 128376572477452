var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basic-data-list"},[_c('el-card',{staticClass:"common-search-card"},[_c('div',{staticClass:"search-input"},[_c('el-input',{staticClass:"input-with-select",attrs:{"placeholder":_vm.$t('lang.gles.orderManage.pleaseReceiptNo')},model:{value:(_vm.formModel.receiptNoOrType),callback:function ($$v) {_vm.$set(_vm.formModel, "receiptNoOrType", $$v)},expression:"formModel.receiptNoOrType"}},[_c('el-button',{attrs:{"slot":"append","type":"primary"},on:{"click":function($event){return _vm.doSearch(_vm.formModel, 'listReceiptMonitorByNoOrType')}},slot:"append"},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.common.query'))+" ")])],1)],1),_c('el-button',{on:{"click":function($event){_vm.moreQueryVisible = true}}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.common.moreQuery'))+" ")])],1),_c('el-card',{staticClass:"table-card"},[_c('div',{staticClass:"operator-wrapper"},[_c('column-config',{attrs:{"list":_vm.columnConfigList},on:{"update:list":function($event){_vm.columnConfigList=$event}}})],1),_c('m-table',{attrs:{"table-item":_vm.tableItem,"table-data":_vm.tableData,"page-data":_vm.pageData,"extend-config":_vm.tableConfig},on:{"pageChange":_vm.pageChange},scopedSlots:_vm._u([{key:"clickSlot",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"list-detail-item",on:{"click":function($event){return _vm.handleDetail(row, 'OrderItem')}}},[_vm._v(" "+_vm._s(row.receiptNo)+" ")])]}},{key:"statusCodeSlot",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(_vm.statusCodeFormat(row))+" ")])]}},{key:"stationSlot",fn:function(ref){
var row = ref.row;
return [(row.receiptType > 0 && row.receiptType < 100)?_c('span',{staticClass:"list-detail-item",on:{"click":function($event){return _vm.handleDetail(row, 'StationItem')}}},[_vm._v(" "+_vm._s(row.demandLocationCode)+" ")]):_c('span',[_vm._v(_vm._s(row.demandLocationCode))])]}},{key:"materialSlot",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"list-detail-item",on:{"click":function($event){return _vm.handleDetail(row, 'MaterialItem')}}},[_vm._v(" "+_vm._s(row.materialCode)+" ")])]}},{key:"robotSlot",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"list-detail-item",on:{"click":function($event){return _vm.handleDetail(row, 'RobotItem')}}},[_vm._v(" "+_vm._s(row.robotContainerId)+" ")])]}},{key:"autoSlot",fn:function(ref){
var row = ref.row;
var item = ref.item;
return [(
            (row.receiptType === 301 &&
              ![0, 100].includes(row.receiptStatus) &&
              [50, 60, 70, 80, 101, 102, 103].includes(row.taskStatus)) ||
            (row.receiptType > 0 &&
              row.receiptType < 100 &&
              ![99, 100].includes(row.receiptStatus) &&
              [50, 60, 70, 80].includes(row.taskStatus))
          )?_c('m-confirm-btn',{key:item.label,attrs:{"label":item.label,"message":item.confirmMessage,"type":"text","confirm-class":'mr10 ml0 f12 ' + (item.customClass || '')},on:{"sureClick":function () { return _vm._operateClick(item.event, row); }}}):_vm._e()]}},{key:"retrySlot",fn:function(ref){
          var row = ref.row;
          var item = ref.item;
return [(
            (row.receiptType > 0 &&
              row.receiptType < 100 &&
              ![99, 100].includes(row.receiptStatus) &&
              ((row.taskId && [3, 23, 30, 24, 41].includes(row.taskStatus)) ||
                (!row.taskId && row.receiptStatus === 50))) ||
            (row.receiptType === 301 &&
              ![0, 100].includes(row.receiptStatus) &&
              ((row.taskId &&
                [3, 23, 30, 24, 41, 42, 70].includes(row.taskStatus)) ||
                (!row.taskId &&
                  [11, -6, -3, 21].includes(row.receiptStatus))))
          )?_c('m-confirm-btn',{key:item.label,attrs:{"label":item.label,"message":item.confirmMessage,"type":"text","confirm-class":'mr10 ml0 f12 ' + (item.customClass || '')},on:{"sureClick":function () { return _vm._operateClick(item.event, row); }}}):_vm._e()]}},{key:"completeSlot",fn:function(ref){
          var row = ref.row;
          var item = ref.item;
return [(
            row.receiptType === 301 ||
            (row.receiptType > 0 && row.receiptType < 100)
          )?_c('m-confirm-btn',{key:item.label,attrs:{"label":item.label,"message":item.confirmMessage,"type":"text","disabled":(row.receiptType > 0 &&
              row.receiptType < 100 &&
              ([99, 100].includes(row.receiptStatus) ||
                (row.taskId &&
                  ![3, 23, 30, 24, 41, 42, 90].includes(row.taskStatus)) ||
                (!row.taskId && row.receiptStatus !== 50))) ||
            (row.receiptType === 301 &&
              ([0, 100].includes(row.receiptStatus) ||
                (row.taskId &&
                  ![3, 23, 30, 24, 41, 42, 70].includes(row.taskStatus)) ||
                (!row.taskId &&
                  ![11, -6, -3, 21].includes(row.receiptStatus)))),"confirm-class":'mr10 ml0 f12 ' + (item.customClass || '')},on:{"sureClick":function () { return _vm._operateClick(item.event, row); }}}):_vm._e()]}},{key:"cancelSlot",fn:function(ref){
                  var row = ref.row;
                  var item = ref.item;
return [_c('m-confirm-btn',{key:item.label,attrs:{"label":item.label,"message":item.confirmMessage,"type":"text","disabled":(row.receiptType < 300 &&
              ([99, 100, 22].includes(row.receiptStatus) ||
                (row.receiptType > 100 &&
                  row.receiptType < 300 &&
                  [0, 50].includes(row.receiptStatus)) ||
                row.taskType === 20 ||
                ([30, 40].includes(row.taskType) &&
                  [80, 100].includes(row.taskStatus)))) ||
            (row.receiptType === 301 &&
              ([0, 100].includes(row.receiptStatus) ||
                (row.taskType === 20 && row.taskStatus === 80))),"confirm-class":'mr10 ml0 f12 ' + (item.customClass || '')},on:{"sureClick":function () { return _vm._operateClick(item.event, row); }}})]}}])})],1),_c('more-query',{ref:"myQueryView",attrs:{"query-list":_vm.moreQueryForm,"visible":_vm.moreQueryVisible},on:{"update:visible":function($event){_vm.moreQueryVisible=$event},"query":_vm.handleMoreQuery}}),(_vm.detailDialogVisible)?_c('detail-dialog',{attrs:{"visible":_vm.detailDialogVisible,"page-type":_vm.pageType,"mode":_vm.mode,"options":_vm.detailOptions,"row-detail":_vm.row},on:{"update:visible":function($event){_vm.detailDialogVisible=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }