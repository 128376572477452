<template>
  <div class="basic-data-list">
    <el-card class="common-search-card">
      <div class="search-input">
        <el-input
          v-model="formModel.receiptNoOrType"
          :placeholder="$t('lang.gles.orderManage.pleaseReceiptNo')"
          class="input-with-select"
        >
          <el-button
            slot="append"
            type="primary"
            @click="doSearch(formModel, 'listReceiptMonitorByNoOrType')"
          >
            {{ $t('lang.gles.common.query') }}
          </el-button>
        </el-input>
      </div>
      <el-button @click="moreQueryVisible = true">
        {{ $t('lang.gles.common.moreQuery') }}
      </el-button>
    </el-card>
    <el-card class="table-card">
      <div class="operator-wrapper">
        <!-- <el-button type="primary" icon="el-icon-plus" @click="updateCom">
          {{ $t('lang.gles.common.addNew') }}
        </el-button> -->
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableConfig"
        @pageChange="pageChange"
      >
        <template #clickSlot="{ row }">
          <span
            class="list-detail-item"
            @click="handleDetail(row, 'OrderItem')"
          >
            {{ row.receiptNo }}
          </span>
        </template>
        <template #statusCodeSlot="{ row }">
          <span>
            {{ statusCodeFormat(row) }}
          </span>
        </template>
        <template #stationSlot="{ row }">
          <span
            v-if="row.receiptType > 0 && row.receiptType < 100"
            class="list-detail-item"
            @click="handleDetail(row, 'StationItem')"
          >
            {{ row.demandLocationCode }}
          </span>
          <span v-else>{{ row.demandLocationCode }}</span>
        </template>
        <template #materialSlot="{ row }">
          <span
            class="list-detail-item"
            @click="handleDetail(row, 'MaterialItem')"
          >
            {{ row.materialCode }}
          </span>
        </template>
        <template #robotSlot="{ row }">
          <span
            class="list-detail-item"
            @click="handleDetail(row, 'RobotItem')"
          >
            {{ row.robotContainerId }}
          </span>
        </template>
        <template #autoSlot="{ row, item }">
          <m-confirm-btn
            v-if="
              (row.receiptType === 301 &&
                ![0, 100].includes(row.receiptStatus) &&
                [50, 60, 70, 80, 101, 102, 103].includes(row.taskStatus)) ||
              (row.receiptType > 0 &&
                row.receiptType < 100 &&
                ![99, 100].includes(row.receiptStatus) &&
                [50, 60, 70, 80].includes(row.taskStatus))
            "
            :key="item.label"
            :label="item.label"
            :message="item.confirmMessage"
            type="text"
            :confirm-class="'mr10 ml0 f12 ' + (item.customClass || '')"
            @sureClick="() => _operateClick(item.event, row)"
          />
        </template>
        <template #retrySlot="{ row, item }">
          <m-confirm-btn
            v-if="
              (row.receiptType > 0 &&
                row.receiptType < 100 &&
                ![99, 100].includes(row.receiptStatus) &&
                ((row.taskId && [3, 23, 30, 24, 41].includes(row.taskStatus)) ||
                  (!row.taskId && row.receiptStatus === 50))) ||
              (row.receiptType === 301 &&
                ![0, 100].includes(row.receiptStatus) &&
                ((row.taskId &&
                  [3, 23, 30, 24, 41, 42, 70].includes(row.taskStatus)) ||
                  (!row.taskId &&
                    [11, -6, -3, 21].includes(row.receiptStatus))))
            "
            :key="item.label"
            :label="item.label"
            :message="item.confirmMessage"
            type="text"
            :confirm-class="'mr10 ml0 f12 ' + (item.customClass || '')"
            @sureClick="() => _operateClick(item.event, row)"
          />
        </template>
        <template #completeSlot="{ row, item }">
          <m-confirm-btn
            v-if="
              row.receiptType === 301 ||
              (row.receiptType > 0 && row.receiptType < 100)
            "
            :key="item.label"
            :label="item.label"
            :message="item.confirmMessage"
            type="text"
            :disabled="
              (row.receiptType > 0 &&
                row.receiptType < 100 &&
                ([99, 100].includes(row.receiptStatus) ||
                  (row.taskId &&
                    ![3, 23, 30, 24, 41, 42, 90].includes(row.taskStatus)) ||
                  (!row.taskId && row.receiptStatus !== 50))) ||
              (row.receiptType === 301 &&
                ([0, 100].includes(row.receiptStatus) ||
                  (row.taskId &&
                    ![3, 23, 30, 24, 41, 42, 70].includes(row.taskStatus)) ||
                  (!row.taskId &&
                    ![11, -6, -3, 21].includes(row.receiptStatus))))
            "
            :confirm-class="'mr10 ml0 f12 ' + (item.customClass || '')"
            @sureClick="() => _operateClick(item.event, row)"
          />
        </template>
        <template #cancelSlot="{ row, item }">
          <m-confirm-btn
            :key="item.label"
            :label="item.label"
            :message="item.confirmMessage"
            type="text"
            :disabled="
              (row.receiptType < 300 &&
                ([99, 100, 22].includes(row.receiptStatus) ||
                  (row.receiptType > 100 &&
                    row.receiptType < 300 &&
                    [0, 50].includes(row.receiptStatus)) ||
                  row.taskType === 20 ||
                  ([30, 40].includes(row.taskType) &&
                    [80, 100].includes(row.taskStatus)))) ||
              (row.receiptType === 301 &&
                ([0, 100].includes(row.receiptStatus) ||
                  (row.taskType === 20 && row.taskStatus === 80)))
            "
            :confirm-class="'mr10 ml0 f12 ' + (item.customClass || '')"
            @sureClick="() => _operateClick(item.event, row)"
          />
        </template>
      </m-table>
    </el-card>
    <!-- 更多查询 -->
    <more-query
      ref="myQueryView"
      :query-list="moreQueryForm"
      :visible.sync="moreQueryVisible"
      @query="handleMoreQuery"
    />
    <!-- 详情 -->
    <detail-dialog
      v-if="detailDialogVisible"
      :visible.sync="detailDialogVisible"
      :page-type="pageType"
      :mode="mode"
      :options="detailOptions"
      :row-detail="row"
    />
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import MoreQuery from '@/components/moreQuery'
import DetailDialog from './detail.vue'
import MTable from '@/libs_sz/components/MTable/MTable'
import {
  getMoreQueryFormData,
  getSearchTableItem,
  gradeStatusList,
  excepteAbnormalList
} from '../data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
import myTransform from '@/libs_sz/utils/transform'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'UpAndDownMaterialOrder',
  components: {
    ColumnConfig,
    MoreQuery,
    MTable,
    DetailDialog
  },
  mixins: [listMixins],
  data() {
    return {
      excepteAbnormalList,
      gradeStatusList,
      pageType: '',
      detailDialogVisible: false,
      tableData: [],
      formModel: {},
      tableConfig: {
        sortNum: false,
        checkBox: false,
        operateWidth: '178px',
        operate: [
          {
            event: 'autoItem',
            label: this.$t('lang.gles.orderMonitor.handleAutoBtn'),
            confirm: true,
            // condition({ row }) {
            //   // 判断是否显示启用 Number(row.receiptStatus) === 0
            //   return row.receiptType > 0 && row.receiptType < 100
            // },
            slotName: 'autoSlot',
            confirmMessage: this.$t('lang.gles.orderManage.isAutoSkip')
          },
          {
            event: 'retryItem',
            label: this.$t('lang.gles.orderMonitor.handleRetryBtn'),
            confirm: true,
            slotName: 'retrySlot',
            confirmMessage: this.$t('lang.gles.orderManage.isSureRetry')
          },
          {
            event: 'completeItem',
            label: this.$t('lang.gles.orderMonitor.handleCompleteBtn'),
            confirm: true,
            slotName: 'completeSlot',
            confirmMessage: this.$t('lang.gles.orderManage.isManualCompletion'),
            customClass: 'success'
          },
          {
            event: 'cancelItem',
            label: this.$t('lang.gles.workflow.taskStatus.cancel'),
            confirm: true,
            slotName: 'cancelSlot',
            confirmMessage: this.$t('lang.gles.strategy.strategy.isSureCancel'),
            customClass: 'danger'
          }
        ]
      },
      // 工厂
      factoryList: [],
      // 车间
      workshopList: [],
      // 仓库
      warehouseList: [],
      // 库区
      warehouseAreaList: [],
      // 库位
      factoryPositionList: [],
      // 区域
      areaList: [],
      // 产线
      productionLineList: [],
      // 设备
      deviceList: [],
      // 设备货位
      goodsPositionList: [],
      // 物料编码
      materialCodeList: [],
      // 物料名称
      materialNameList: [],
      // 所有容器编码
      containerArchivesAllList: [],
      // 周转容器编码
      turnoverContainerCodeList: [],
      // 容器类型
      containerTypeList: [],
      materialCodeObj: {},
      // 单据状态列表
      // receiptStatusList: [],
      // 日志专用单据状态
      logStatusList: []
    }
  },
  computed: {
    ...mapState('base', [
      'operatingModeList',
      'receiptTypeList',
      'taskStatusList',
      'robotWorkTypeList',
      'baseGoodsPositionTypeList',
      'storeInStatusList',
      'storeOutStatusList',
      'materialsBillStatusList',
      'tallyReceiptStatusList',
      'receiptStatusList'
    ]),
    ...mapState('materialManage', ['basicUnitList']),
    ...mapState('strategyManage', [
      'robotTypeList',
      'robotModelList',
      'taskTypeList'
    ]),
    moreQueryForm() {
      return getMoreQueryFormData(this)
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      // console.log(this.detailOptions.taskTypeList)
      return getSearchTableItem(this, this.detailOptions)
    },
    detailOptions() {
      const options = {
        factoryList: this.factoryList,
        workshopList: this.workshopList,
        warehouseList: this.warehouseList,
        warehouseAreaList: this.warehouseAreaList,
        factoryPositionList: this.factoryPositionList,
        areaList: this.areaList,
        productionLineList: this.productionLineList,
        deviceList: this.deviceList,
        goodsPositionList: this.goodsPositionList,
        receiptTypeList: this.receiptTypeList,
        operatingModeList: this.operatingModeList,
        taskStatusList: this.taskStatusList,
        robotWorkTypeList: this.robotWorkTypeList,
        materialCodeList: this.materialCodeList,
        materialNameList: this.materialNameList,
        containerTypeList: this.containerTypeList,
        containerArchivesAllList: this.containerArchivesAllList,
        basicUnitList: this.basicUnitList,
        turnoverContainerCodeList: this.turnoverContainerCodeList,
        gradeStatusList: this.gradeStatusList,
        baseGoodsPositionTypeList: this.baseGoodsPositionTypeList,
        taskTypeList: this.taskTypeList,
        logStatusList: this.logStatusList
      }
      return options
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
    // pageType222() {
    //   let type = ''
    //   if (this.rowDetail.receiptType > 0 && this.rowDetail.receiptType < 100) {
    //     type = 'upMaterial'
    //   } else if (this.rowDetail.receiptType > 100 && this.rowDetail.receiptType < 200) {
    //     type = 'warehousing'
    //   } else if (this.rowDetail.receiptType > 200 && this.rowDetail.receiptType < 300) {
    //     type = 'outWarehouse'
    //   }
    //   return type
    // }
  },
  async created() {
    this.initSelectList()
    this.doSearch(this.formModel)
  },
  methods: {
    ...mapActions('materialManage', ['getBasicUnitList']),
    // handleChange(val) {
    //   const obj = {
    //     receiptType: val
    //   }
    //   this.receiptStatusList = this.statusList(obj)
    // },
    statusList(row) {
      let list = []
      if (row.receiptType > 0 && row.receiptType < 100) {
        list = this.materialsBillStatusList
      } else if (row.receiptType > 100 && row.receiptType < 200) {
        list = this.storeInStatusList
      } else if (row.receiptType > 200 && row.receiptType < 300) {
        list = this.storeOutStatusList
      } else if (row.receiptType > 300) {
        list = this.tallyReceiptStatusList
      }
      return list
    },
    statusCodeFormat(row) {
      const list = this.statusList(row)
      console.log('statusCodeFormat', list)
      const statusObj = myTransform.arrToObject(list, 'value', 'label')
      // console.log(list)
      return statusObj[row.receiptStatus]
    },
    // 单据状态
    initStatusData() {
      if (!this.storeInStatusList?.length) {
        this.$store.dispatch('base/getStoreInStatusList')
      }
      if (!this.storeOutStatusList?.length) {
        this.$store.dispatch('base/getStoreOutStatusList')
      }
      if (!this.materialsBillStatusList?.length) {
        this.$store.dispatch('base/getMaterialsBillStatusList')
      }
      if (!this.tallyReceiptStatusList?.length) {
        this.$store.dispatch('base/getTallyReceiptStatusList')
      }
      if (!this.receiptStatusList?.length) {
        this.$store.dispatch('base/getReceiptStatusList')
      }
    },
    /**
     * 初始化下拉选项
     */
    initSelectList() {
      this.getBaseData(
        'getWarehouseAreaDictList',
        'warehouseAreaList',
        'warehouseAreaCode'
      )
      this.getBaseData(
        'getFactoryPositionDictList',
        'factoryPositionList',
        'factoryPositionCode'
      )
      this.getBaseData('getDeviceDictList', 'deviceList', 'deviceCode')
      this.getBaseData(
        'getGoodsPositionListByType',
        'goodsPositionList',
        'goodsPositionCode'
      )
      // this.getBaseData('getDeviceList', 'deviceList', 'deviceCode')
      this.initStatusData()
      this.getMaterialCodeList()
      this.getContainerTypeList()
      this.getTurnoverContainerCodeList()
      this.getContainerArchivesAllList()
      if (!this.baseGoodsPositionTypeList?.length) {
        this.$store.dispatch('base/getBaseGoodsPositionTypeList')
      }
      if (!this.operatingModeList?.length) {
        this.$store.dispatch('base/getOperatingModeList')
      }
      if (!this.receiptTypeList?.length) {
        this.$store.dispatch('base/getReceiptTypeList')
      }
      if (!this.taskStatusList?.length) {
        this.$store.dispatch('base/getTaskStatusList')
      }
      if (!this.robotWorkTypeList?.length) {
        this.$store.dispatch('base/getRobotWorkTypeList')
      }
      if (!this.taskTypeList?.length) {
        this.$store.dispatch('strategyManage/getTaskTypeList')
      }
      if (!this.basicUnitList?.length) {
        this.getBasicUnitList()
      }
    },
    async getBaseData(url, array, label) {
      const { data } = await this.$httpService(this.$apiStore.base(url))
      const list = data.map((item) => ({
        label: label === 'factoryPositionCode' ? item[label] : item.code,
        value: item.id,
        ...item
      }))
      this[array] = list
      // console.log(this[array], array)
    },
    // 获取物料编码的下拉数据
    async getMaterialCodeList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('queryMaterialOption')
      )
      if (code) return
      this.materialCodeList = data.map((item) => {
        return { label: item.materialCode, value: item.id }
      })
      this.materialCodeObj = myTransform.arrToObject(
        this.materialCodeList,
        'value',
        'label'
      )
      this.materialNameList = data.map((item) => {
        return { label: item.materialName, value: item.id }
      })
    },
    // 获取容器类型
    async getContainerTypeList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('getContainerType')
      )
      if (code) return
      this.containerTypeList = myTransform.arrToOptions(
        data,
        'containerTypeName',
        'containerTypeId'
      )
    },
    // 所有容器编码
    async getContainerArchivesAllList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.orderManage('getContainerArchivesAllList')
      )
      if (code) return
      this.containerArchivesAllList =
        data &&
        data.map((item) => {
          return { label: item.containerArchivesCode, value: item.id }
        })
    },
    // 获取周转容器编码
    async getTurnoverContainerCodeList() {
      const params = {
        containerTypePatterns: [
          'containerPatternCrate',
          'containerPatternBarrel'
        ]
      }
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('getContainerCode'),
        params
      )
      if (code) return
      this.turnoverContainerCodeList = myTransform.arrToOptions(
        data,
        'containerArchivesCode',
        'id'
      )
    },
    /**
     * 查看详情
     */
    handleDetail(row, pageType) {
      // row.receiptType = 50
      if (pageType === 'OrderItem') {
        this.logStatusList = this.statusList(row)
      }
      this.pageType = pageType
      this.row = row
      this.mode = 'detail'
      this.detailDialogVisible = true
    },
    dataFun(data) {
      const obj = this._.cloneDeep(data)
      for (const key in obj) {
        if (obj[key] === '') {
          delete obj[key]
        }
      }
      return obj
    },
    /**
     * 更多查询
     */
    handleMoreQuery(event) {
      this.formModel = { ...event }
      this.pageData.currentPage = 1
      const params = this.dataFun(event)
      this.doSearch(params, 'listReceiptMonitorByPage')
      // this.$refs.myQueryView.reset()
    },
    /**
     * 接口查询
     */
    async doSearch(params, api = 'listReceiptMonitorByPage') {
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.monitorManage(api, this.pageData),
          params
        )
        this.$store.commit(types.IS_LOADING, false)
        if (code) return
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount: totalRecordCount }
        this.tableData = recordList || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    // 自动跳过
    autoItem({ row }) {
      // console.log('自动跳过')
      const params = {
        receiptNo: row.receiptNo,
        receiptType: row.receiptType
      }
      this.$httpService(this.$apiStore.monitorManage('postSkip'), params).then(
        ({ code }) => {
          if (code) return
          this.$message.success(this.$t('lang.gles.common.handleSuccess'))
          this.doSearch(this.formModel)
        }
      )
    },
    // 重试
    retryItem({ row }) {
      // console.log('重试')
      const params = {
        receiptNo: row.receiptNo,
        receiptType: row.receiptType
      }
      this.$httpService(this.$apiStore.monitorManage('postRetry'), params).then(
        ({ code }) => {
          if (code) return
          this.$message.success(this.$t('lang.gles.common.handleSuccess'))
          this.doSearch(this.formModel)
        }
      )
    },
    completeItem({ row }) {
      // console.log('人工完成')
      const params = {
        receiptNo: row.receiptNo,
        receiptType: row.receiptType
      }
      this.$httpService(
        this.$apiStore.monitorManage('postFinish'),
        params
      ).then(({ code }) => {
        if (code) return
        this.$message.success(this.$t('lang.gles.common.handleSuccess'))
        this.doSearch(this.formModel)
      })
    },
    /**
     * 取消
     */
    cancelItem({ row }) {
      console.log(row)
      const params = {
        receiptNo: row.receiptNo,
        receiptType: row.receiptType
      }
      this.$httpService(
        this.$apiStore.monitorManage('postCancel'),
        params
      ).then(({ code }) => {
        if (code) return
        this.$message.success(this.$t('lang.gles.common.handleSuccess'))
        this.doSearch(this.formModel)
      })
    },
    _operateClick(event, row) {
      this[event]({ row })
    },
    pageChange(pageData) {
      this.pageData = { ...this.pageData, ...pageData }
      this.doSearch(this.formModel)
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-button.is-disabled {
  color: #c0c4cc;
}
</style>
