<template>
  <el-drawer
    :visible="visible"
    size="80%"
    custom-class="gles-drawer"
    :with-header="false"
    @update:visible="$emit('update:visible', $event)"
  >
    <component
      :is="pageType"
      :row-detail="rowDetail"
      :options="options"
      @close="$emit('update:visible', $event)"
    />
  </el-drawer>
</template>
<script>
import OrderItem from './orderItem.vue'
import RobotItem from './robotItem.vue'
import MaterialItem from './materialItem.vue'
import StationItem from './stationItem.vue'
import MForm from '@/libs_sz/components/MForm/MForm'
import myTransform from '@/libs_sz/utils/transform'
import { getEditBaseFormData, getDetailTableItems, getTaskTableItems } from '../data'
import addMixins from '@/mixins/addMixins'

export default {
  components: {
    MForm,
    OrderItem,
    MaterialItem,
    RobotItem,
    StationItem
  },
  mixins: [addMixins],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'add'
    },
    pageType: {
      type: String,
      default: 'OrderItem'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      activeName: 'upMaterial',
      // currentCom: 'OrderItem',
      detailObj: {},
      tableData: [],
      extendFormConfig: {
        isNeedBtn: false
      },
      pageData: null
    }
  },
  computed: {
    statusValue() {
      const val = myTransform.arrToObject(this.options.statusCode_dict, 'value', 'label')[this.rowDetail.statusCode] || this.rowDetail.statusCode
      return val
    },
    baseFormData() {
      const row = this.rowDetail || {}
      return getEditBaseFormData(this, { ...row, ...this.detailObj }, this.options)
    },
    tableItem() {
      return getDetailTableItems(this)
    },
    tableTaskItem() {
      return getTaskTableItems(this)
    }
  },
  watch: {
    activeName(val) {
      switch (val) {
        case 'info':
          this.currentCom = 'WarehousingInfo'
          break
        case 'task':
          this.currentCom = 'WarehousingTask'
          break
        case 'log':
          this.currentCom = 'LogInfo'
          break
      }
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.el-table__header-wrapper {
  overflow: auto;
}
/deep/ .el-drawer__header {
  margin-bottom: 5px;
}
/deep/ .el-drawer__body {
  overflow-y: scroll;
  padding: 0 15px;
}
.drawer-wrapper {
  padding: 0;
}
// .drawer-head {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 20px 0px 10px;
// }
.drawer-info {
  display: flex;

  p {
    padding-left: 10px;
  }
}
.baseinfo {
  margin-bottom: 10px;
}
.baseinfo-title, .taskinfo_title {
  margin-bottom: 10px;
  font-weight: 800;

  &::before {
    content: "";
    display: inline-block;
    height: 21px;
    width: 4px;
    border-radius: 4px;
    background: #409eff;
    margin-right: 10px;
    vertical-align: text-bottom;
  }
}
</style>
