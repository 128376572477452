<template>
  <div class="drawer-wrapper">
    <div class="drawer-operate-btn">
      <i class="el-icon-close" @click="handleClose" />
    </div>
    <m-form
      ref="myForm"
      :form-data="baseFormData"
      label-position="right"
      :label-width="120"
      :extend-config="extendFormConfig"
    />
  </div>
</template>
<script>
import MForm from '@/libs_sz/components/MForm/MForm'
import myTransform from '@/libs_sz/utils/transform'
import { getStationFormData } from '../data'

export default {
  components: {
    MForm
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      detailObj: {},
      tableData: [],
      extendFormConfig: {
        isNeedBtn: false,
        isGroup: true
      },
      pageData: null
    }
  },
  computed: {
    baseFormData() {
      // console.log(this.options.baseGoodsPositionTypeList)
      return getStationFormData(this, { ...this.detailObj }, this.options)
    }
  },
  watch: {
    detailObj: {
      handler() {
        this.$nextTick(() => {
          this.$refs.myForm.changeFormValue(
            this.detailObj ?? {}
          )
        })
      },
      deep: true
    }
  },
  created() {
    this.getGoodsPositionInfoById()
  },
  methods: {
    async getGoodsPositionInfoById() {
      const params = {
        workstationId: this.rowDetail.demandLocationId
      }
      const { data } = await this.$httpService(
        this.$apiStore.monitorManage('getGoodsPositionInfoById'),
        params
      )
      console.log(data)
      data.workstationTypeDec = myTransform.arrToObject(this.options.baseGoodsPositionTypeList, 'value', 'label')[data.workstationType]
      this.detailObj = data || {}
    },
    // 关闭
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-wrapper {
  padding: 10px 0 0 0 !important;
}
.drawer-operate-btn {
  height: 40px;
}
</style>
